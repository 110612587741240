import Component from '../../libs/component';
import { register } from '../../libs/register';
import { forceUpdateLazy } from '../../components/media-picture';
import { getURLParams } from '../../libs/utils';



class CardsList extends Component {

    constructor(container) {
        super('widget-cards-list');

        this.container = container;
        this.filters = container.querySelector(this._el('filters',true));
        this.filterBtn = container.querySelector(this._el('open-filters',true));
        this.cardsContainer = container.querySelector(`${this._el('item-container',true)}`);
        this.masonryLayout = this.cardsContainer.querySelector('.layout-masonry');
        this.message = container.querySelector(`${this._el('message',true)}`);
        this.loadMore = container.querySelector(`${this._el('load-more',true)}`);
        this.resetButton = this.filters.querySelector(this._el('reset-filters',true));
        this.filterModal = container.querySelector(`${this._el('filters-modal',true)} .widget-modal`);
        this.xingEnabled = Boolean(this.cardsContainer.dataset.xingEnabled !== undefined);

        this._init();
    }

    _init() {

        this.endpoint = this.container.dataset.url || '';
        delete this.container.dataset.url;

        this.excludepaths = this.container.dataset.excludepaths || '';
        this.excludepaths = encodeURIComponent(this.excludepaths);
        delete this.container.dataset.excludedpaths;

        this.masonry = this.masonryLayout ? this.masonryLayout.objReference : null;

        let loadMoreBtn = this.loadMore.querySelector('button');

        if (loadMoreBtn) {
            
            loadMoreBtn.addEventListener('click', (ev) => {
                ev.preventDefault();
                this._fetchCards(this._getFilters(),this.masonry.itemsCount(),true);
            });

            //if (this.masonry.itemsCount() < 9)
                //this._showLoadMoreButton(false);
        }

        if (this.filters) {

            this.resetButton.addEventListener('click',() => {
                
                this.filterSelects.forEach((fl) => {
                    fl.objReference.resetSelect();
                });
                this._resetFilterOpenBtn();
                this._showResetButton(false);
                this.filterModal.objReference.resetAccordionFilters();

                this._removeAllCards();
                this._setQueryUrlParams();
                this._fetchCards([],0,true);
            });

            this.filterSelects = this.filters.querySelectorAll('.elem-custom-select.list-filter');

            this.filterSelects.forEach((fl) => {
                fl.addEventListener('filtersValidated',({detail}) => {

                    this.modal.setAccordionFilters(detail.selectValidated);
                    let filtersCount = this.modal.getValidatedFiltersCount();
                    
                    this._showResetButton( filtersCount ? true : false );
                    this._updateFilterOpenBtn(filtersCount);

                    this._removeAllCards();
                    this._setQueryUrlParams(detail.selectValidated);
                    this._fetchCards(this._getFilters());
                    
                });
            });
 
        }

        if (this.filterModal) {

            this.modal = this.filterModal.objReference;

            this.filterModal.addEventListener('filtersValidated',({detail}) => {

                this._updateSelectsFilter(detail.accordionValidated);
                this._updateFilterOpenBtn(detail.accordionValidated);
                this._showResetButton( this.modal.getValidatedFiltersCount() ? true : false );

                this._removeAllCards();
                this._setQueryUrlParams(detail.accordionValidated);
                this._fetchCards(detail.accordionValidated);
            });

            this.filterModal.addEventListener('filtersReset',() => {

                this._resetSelectsFilter();
                this._resetFilterOpenBtn();
                this._showResetButton(false);

                this._removeAllCards();
                this._setQueryUrlParams();
                this._fetchCards([],0,true);
            });
        }

        this.container.classList.add('init');

        this._loadUrlParamsToFilters();
        this._showResetButton( this.modal.getValidatedFiltersCount() ? true : false );
        this._checkCardsCount();

        //console.log(this.masonry)
    }

    _loadUrlParamsToFilters() {
        
        let filterParams = this._getQueryUrlParams();

        if (filterParams) {

            console.log(filterParams);

            this._updateSelectsFilter(filterParams);
            this._updateFilterOpenBtn(filterParams);

            let modal = this.filterModal.objReference;

            for (let up in filterParams) {
                if (!filterParams[up]) continue;
                modal.setAccordionFilters(filterParams[up]);
            }
        }
    }

    _updateSelectsFilter(validatedData) {

        validatedData.forEach((val) => {
            let select = this.filters.querySelector(`[data-type="${val.type}"]`);
            let customSelect = select.closest('.elem-custom-select').objReference;
            customSelect.setValidatedFilters(val.validated);
        });
    }

    _updateFilterOpenBtn(validated) {

        if (this.filterBtn && this.filterModal) {

            let text = this.filterBtn.querySelector('span');
            let total = 0;

            if (Array.isArray(validated)) {
                validated.forEach((val)=> {
                    total += val.validated.length;
                });
            } else {
                total = validated;
            }

            text.textContent = total ? ` (${total})` : '';
        }
    }

    _resetFilterOpenBtn() {

        let text = this.filterBtn.querySelector('span');
        text.textContent = '';
    }

    _resetSelectsFilter() {

        this.filterSelects.forEach((sel) => {
            sel.objReference.resetSelect(true);
        });
    }

    _getFiltersType() {

        let types = {};

        this.filterSelects.forEach((fl) => {
            types[ fl.objReference.getSelectType() ] = [];
        });

        return types;
    }

    _getQueryUrlParams() {

        let params = getURLParams();
        let types = this._getFiltersType();
        let filterParams = [];

        console.log(params, types);

        for (let p in params) {
            if (!params[p]) continue;
            if (p in types) {
                filterParams.push({
                    type: decodeURIComponent(p),
                    validated: decodeURIComponent(params[p]).split(',')
                });
            }
        }

        return filterParams.length ? filterParams : null;
    }

    _setQueryUrlParams(params) {

        console.log("*** SETTING URL PARAMS:",params);

        let types = this._getFiltersType();
        let urlParams = (new URL(document.location)).searchParams;
        let searchSeparator = '?';

        if (params === null || params === undefined) {

            for (let type in types) {
                if (!types[type]) continue;
                urlParams.delete(type);
            }

            if (urlParams.toString() === '')
                searchSeparator = '';

        } else {

            if (typeof params === 'object' && !Array.isArray(params))
                params = [ params ];

            for (let obj of params) {
                if (obj.type in types) {
                    if (obj.validated.length) {
                        if (urlParams.has(obj.type))
                            urlParams.set(obj.type,obj.validated.toString());
                        else
                            urlParams.append(obj.type,obj.validated.toString());
                    } else {
                        urlParams.delete(obj.type);
                    }
                }
            }
        }

        window.history.replaceState({}, '', `${location.pathname}${searchSeparator}${decodeURIComponent(urlParams.toString())}`);

        console.log('*** NEW URL PARAMS:',document.location.search);
    }

    _getFilters() {

        let filters = [];

        if (!this.filterSelects)
            this.filterSelects = this.filters.querySelectorAll('.elem-custom-select.list-filter');

        this.filterSelects.forEach((sel) => {
            let customSelect = sel.objReference;
            let validatedData = customSelect.getValidatedFilters();
            
            if (validatedData.length)
                filters.push({ type: customSelect.getSelectType(), validated: validatedData });
        });

        return filters;
    }

    _fetchCards(filters=[],offset=0,excludedPaths=false) {

        let request = new XMLHttpRequest();
        let resPath = this.endpoint ? this.endpoint + '?' : '/assets/files/news-test.json?';
        //let resPath = this.endpoint ? this.endpoint + '?': 'http://localhost:4502/content/corp/en/news/jcr:content/root/responsivegrid/news_list.newsList.json?';
        //let pointer = offset ? offset : this.masonry.itemsCount();
        let params = '';

        this._addSkeletonCards();

        if (offset) {
            resPath += `offset=${offset}&`;
        }

        if (this.excludepaths && excludedPaths && !filters.length)
            resPath += `excludepaths=${this.excludepaths}&`;
        
        if (filters.length) {
            
            filters.forEach((val) => {
                if (val.validated.length) {
                    let values = encodeURIComponent(val.validated.toString());
                    values = values.replace(/%2C/g,",");
                    params += `${val.type}=${values}&`;
                }
            });

            resPath += params;
        }

        console.log('*** Request path:',resPath);

        request.open('GET',resPath,true);
        request.timeout = 10000;
        request.responseType = 'json';
        request.onload = () => {

            if (request.status === 200) {

                try {
                    let dataObj = (typeof request.response === 'string' || request.response instanceof String) ? JSON.parse(request.response) : request.response;

                    if (!dataObj.itemsLeft) {
                        this._showLoadMoreButton(false);
                    } else {
                        this._showLoadMoreButton();
                    }

                    this._insertCards(dataObj.news,dataObj.authorLabel);

                } catch(err) {
                    console.warn('Cards list fetch ERROR:',err);
                }
            } else {
                // show error message?
                console.log(`Card list Error ${request.status}: ${request.statusText}`);
            }

            if (request.status === 204 && this.loadMore)
                this._showLoadMoreButton(false);

            this._removeSkeletonCards();
            this._checkCardsCount();
        }

        request.onerror = () => {
            console.warn('Cards list loading network ERROR');
            this._removeSkeletonCards();
            this._checkCardsCount();
        };
        
        request.send();
    }

    _insertCards(cardsData,otherLabel) {

        if (cardsData) {

            cardsData.forEach((card) => {

                let cardItem = document.createElement('div');
                    cardItem.classList.add('elem-card')
                    cardItem.classList.add('elem-card--share');
                
                if (this.xingEnabled)
                    cardItem.dataset.xingEnabled = '';

                let cardContainer = document.createElement('div');
                    cardContainer.classList.add('elem-card__container');
                    cardItem.appendChild(cardContainer);

                if (card.enableShareButton) {
                    let shareButton = document.createElement('button');
                        shareButton.classList.add('elem-card__open-share-panel');
                        //shareButton.dataset.url = card.cardLink;
                        shareButton.innerHTML = '<em class="icon-share"></em><em class="icon-close"></em>';
                        cardContainer.appendChild(shareButton);
                }

                let link = document.createElement('a');
                    link.classList.add('elem-card__overlay');
                    link.href = card.url;
                    link.target = '_self';
                    cardContainer.appendChild(link);

                let cardContent = document.createElement('div');
                    cardContent.classList.add('elem-card__content');
                    cardContainer.appendChild(cardContent);

                if (card.launchImage) {

                    let srcMobile = card.launchImage['srcMobile'];
                    let srcTablet = card.launchImage['srcTablet'];
                    let srcDesktop = card.launchImage['srcDesktop'];

                    if (srcMobile || srcTablet || srcDesktop) {

                        let imgContainer = document.createElement('div');
                            imgContainer.classList.add('elem-card__image');
                            cardContent.appendChild(imgContainer);

                        let picture = document.createElement('picture');
                            picture.classList.add('media-picture');
                            picture.classList.add('media-picture--lazy');
                            imgContainer.appendChild(picture);

                        if (card.launchImage.srcMobile !== 'null' && card.launchImage.srcMobile !== '') {
                            let pictureSourceMobile = document.createElement('source');
                                pictureSourceMobile.media = '(max-width: 767px)';
                                pictureSourceMobile.dataset.srcset =  srcMobile;
                                //picture.appendChild(pictureSourceMobile);
                        }

                        if (card.launchImage.srcTablet !== 'null' && card.launchImage.srcTablet !== '') {
                            let pictureSourceTablet = document.createElement('source');
                                pictureSourceTablet.media = '(max-width: 1023px)';
                                pictureSourceTablet.dataset.srcset =  srcTablet;
                                //picture.appendChild(pictureSourceTablet);
                        }

                        let pictureSourceDesktop = document.createElement('source');
                            pictureSourceDesktop.dataset.srcset =  srcDesktop;
                            //picture.appendChild(pictureSourceDesktop);

                        let img = document.createElement('img');
                            img.src = 'data:image/gif;base64,R0lGODlhAwACAIAAAP///wAAACH5BAEAAAEALAAAAAADAAIAAAICjF8AOw==';
                            img.dataset.src = srcTablet || srcDesktop;
                            img.alt = card.title + ' image';
                            img.classList.add('media-picture__img');
                            picture.appendChild(img);
                    }
                }

                let cardDetails = document.createElement('div');
                    cardDetails.classList.add('elem-card__details');
                    cardContent.appendChild(cardDetails);

                if (card.location || card.formattedDate) {
                    let decodedDate = card.formattedDate ? decodeURIComponent(card.formattedDate) : '';
                    let dataText = card.location || '';
                    let preTitle = document.createElement('div');
                        preTitle.appendChild(document.createTextNode(dataText));
                        preTitle.classList.add('elem-card__pretitle');
                        preTitle.innerHTML += card.location && decodedDate ? ', ' : '';
                        preTitle.innerHTML += decodedDate || '';
                        cardDetails.appendChild(preTitle);
                }

                if (card.title) {
                    let title = document.createElement('div');
                        title.appendChild(document.createTextNode(card.title));
                        title.classList.add('elem-card__title');
                        cardDetails.appendChild(title);
                }
                
                if (card.description) {
                	let description = document.createElement('div');
                    	description.classList.add('elem-card__description');
                    	description.insertAdjacentHTML('afterbegin', card.description);
                    	cardDetails.appendChild(description);
                }

                if (card.author || card.readingTime) {
                    let auLabel = otherLabel || '';
                    let arText = card.author ? auLabel + card.author : '';
                        arText += card.author && card.readingTime ? ' | ' : '';
                        arText += card.readingTime || '';
                    let footer = document.createElement('div');
                        footer.appendChild(document.createTextNode(arText));
                        footer.classList.add('elem-card__footer-text');
                        cardDetails.appendChild(footer);
                }

                if (card.tagTitles) {
                    let tags = document.createElement('div');
                        tags.classList.add('elem-tags');
                        cardDetails.appendChild(tags);

                    card.tagTitles.forEach((tag) => {
                        let newTag = document.createElement('span');
                            newTag.appendChild(document.createTextNode(tag));
                            newTag.classList.add('elem-tags__item');
                            tags.appendChild(newTag);
                    });
                }

                if (card.url) {
                let buttonTextLink = document.createElement('span');
                    buttonTextLink.classList.add('btn-text-link');
                    buttonTextLink.innerHTML = this.loadMore.getAttribute('discover-more-i18n');
                    cardDetails.appendChild(buttonTextLink);

                    let iconArrowRight = document.createElement('em');
                    iconArrowRight.classList.add('icon-arrow_right');
                    buttonTextLink.appendChild(iconArrowRight);
                }

                this.masonry.addItems(cardItem,false);

                register.applyTo(cardItem);
                forceUpdateLazy();
            });

            this.masonry.update();
        }
    }

    _removeAllCards() {
        this.masonry.removeItems();
    }

    _addSkeletonCards() {

        const rndInt = this._randomIntFromInterval(6,9);
        let skeletonCards = [];

        for (let i=0; i < rndInt; i++) {

            let cardSkeleton =
                `<div class="elem-card__container">
                    <div class="elem-card__open-share-panel"></div>
                    <div class="elem-card__content">
                        <div class="elem-card__image">
                            <picture class="media-picture media-picture--lazy"></picture>
                        </div>
                        <div class="elem-card__details">
                            <div class="elem-card__pretitle"></div>
                            <div class="elem-card__title"></div>
                            <div class="elem-card__footer-text"></div>
                            <div class="elem-tags"></div>
                            <span class="btn-text-link"></span>
                        </div>
                    </div>
                </div>`;

            let cardItem = document.createElement('div');
                cardItem.className = 'elem-card elem-card--share skeleton-card';
                cardItem.innerHTML = cardSkeleton;

            let cardImage = cardItem.querySelector('.elem-card__image');
            let rndImage = this._randomIntFromInterval(1,3);
            if (rndImage === 3) {
                let cardContent = cardItem.querySelector('.elem-card__content');
                cardContent.removeChild(cardImage);
            }
            
            let cardTitle = cardItem.querySelector('.elem-card__title');
            let rndTitle = this._randomIntFromInterval(1,3);
            for (let i=0; i < rndTitle; i++) {
                cardTitle.appendChild(document.createElement('div'));
            }

            let cardTags = cardItem.querySelector('.elem-tags');
            let rndTags = this._randomIntFromInterval(1,3);
            for (let i=0; i < rndTags; i++) {
                cardTags.innerHTML += '<span class="elem-tags__item"></span>';
            }

            skeletonCards.push(cardItem);
        }

        this.masonry.addItems(skeletonCards);

        //window.deviceBreakpoints.bpDesktop.matches
    }

    _removeSkeletonCards() {
        this.masonry.removeItems('.skeleton-card');
    }

    _checkCardsCount() {

        if (this.masonry)
            this._showNoCardsMessage( this.masonry.itemsCount() === 0 );
    }

    _showNoCardsMessage(show=true) {

        if (this.message) {

            if (show) {
                this.message.classList.remove('hidden');
            } else {
                this.message.classList.add('hidden');
            }
        }
    }

    _showLoadMoreButton(show=true) {

        if (this.loadMore) {

            if (show) {
                this.loadMore.classList.remove('hidden');
            } else {
                this.loadMore.classList.add('hidden');
            }
        }
    }

    _showResetButton(show=true) {
        
        if (show)
            this.resetButton.classList.remove('hidden');
        else
            this.resetButton.classList.add('hidden');

        this.modal.showResetButton(show);
    }

    _randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}

register.registerClass('.widget-cards-list', CardsList);
