//REGISTER
import { register } from '../libs/register';

////COMPONENTS
// Base structure
import './base-page';
import './base-header';

// Layout
import './layout-masonry';
import './layout-wrap-text';

// Elements
import './elem-custom-select';
import './elem-custom-select-filter-table';
import './elem-scroll-to-top';
import './elem-quick-links';
import './elem-cta-modal';
import './elem-card';
import './elem-iframe';
import './elem-icon-anim';
import './elem-slideshow';

// Media
import './media-picture';
import './media-video';

//Widget
import './widget-modal';
import './widget-tab';
import './widget-table';
import './widget-carousel';
import './widget-accordion-panel';
import './widget-accordion-select';
import './widget-gallery';
import './widget-cards-list';
import './widget-calendar-events';
import './widget-donut-graph';
import './widget-share';
import './widget-logos-grid';
import './widget-history';
import './widget-country-hero';
import './widget-past-events';
import './widget-tab-slide';
import './widget-form-info';


register.apply(document);
